import React from "react";
import Footer from './footer';

import "bootstrap/dist/css/bootstrap.min.css";
import '../css/style.css';

export default function Layout({ children }) {
  return (
    <React.Fragment>
      
      {children}
      <Footer></Footer>
    </React.Fragment>
  )
}
