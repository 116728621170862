import React, { useState, Component } from 'react';
import { Link } from "gatsby";
import SiteLogo from '../components/sitelogo';
import Disclaimer from '../pages/disclaimer';
import { Helmet } from "react-helmet";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            disclaimer: true
        }
    }


    MegaMenu = () => {
        if (this.state.isVisible == true) {
            this.setState({ isVisible: false })
        } else {
            this.setState({ isVisible: true })
        }
    }



    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    async componentDidMount() {
        if (typeof window !== 'undefined') {
            let disclaimer = sessionStorage.getItem('disclaimer') || true;
            this.setState({ disclaimer: disclaimer })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (typeof window !== 'undefined') {
            let disclaimer = sessionStorage.getItem('disclaimer') || true;
            this.setState({ disclaimer: disclaimer })
        }
    }

    handleTitleChange = (value) => {
        console.log(JSON.stringify("value", value))
        sessionStorage.setItem('disclaimer', false);
        this.setState({ disclaimer: false })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta property="og:url" content="https://tlegal.com/" />
                    <title>{this.props.title === null || this.props.title === undefined || this.props.title.length === 0 ? `Tatva Legal | Complete Law Firm Service in Hyderabad` : `${this.props.title} `}</title>
                    <meta name="description" content={this.props.description === null || this.props.description === undefined || this.props.description.length === 0 ? `The best legal service firm in Hyderabad for businesses, operations and investments. Tatva Legal also provide the legal services for local laws and local practices.` : `${this.props.description} `} />
                    <meta name="keywords" content={this.props.keywords === null || this.props.keywords === undefined || this.props.keywords.length === 0 ? `legal services firm, legal compliance, best law firm, best lawyer firm in Telangana, legal services in Andhra Pradesh, corporate legal firm, corporate law firm hyderabad` : `${this.props.keywords} `} />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Tatva Legal | Complete Law Firm Service in Hyderabad" />
                    <meta property="og:description" content="Tatva Legal | Complete Law Firm Service in Hyderabad" />
                    <meta property="og:site_name" content="Tatva Legal | Complete Law Firm Service in Hyderabad" />
                    <meta property="og:image" content="https://tatva.com/backendapi/public/images/media-16223610083-featured_logo.jpg" />
                    <meta property="og:image:width" content="1000" />
                    <meta property="og:image:height" content="667" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:title" content="Tatva Legal | Complete Law Firm Service in Hyderabad" />
                    <meta property="twitter:image" content="https://tatva.com/backendapi/public/images/media-16223610083-featured_logo.jpg" />
                    <link rel="icon" type="image/png" sizes="32x32" href="https://tatva.com/backendapi/public/images/media-16223610083-favicon.ico" />
                    <link rel="icon" type="image/png" sizes="96x96" href="https://tatva.com/backendapi/public/images/media-16223610083-favicon.ico" />
                    <link rel="icon" type="image/png" sizes="16x16" href="https://tatva.com/backendapi/public/images/media-16223610083-favicon.ico" />
                    <meta name="google-site-verification" content="sOOo-Vro89rWnT2g0z9GX-UakSa16NtpBGj97l2LnBs" />
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(
                                {
                                    "@context": "http://schema.org/",
                                    "@type": "Law Services Firm in Hyderabad",
                                    "name": "Tatva Legal",
                                    "description": "Tatva Legal is a professional law service firm in Hyderabad and has consistently been ranked as the best regional law firm in India over the last seven years.",
                                    "aggregateRating": {
                                        "@type": "AggregateRating",
                                        "ratingValue": "4.5",
                                        "ratingCount": "57",
                                        "reviewCount": "57"
                                    }
                                })
                        }}
                    />;
                </Helmet>
                <div className="site-logo" id="top">
                    <div className="site_navigation desktop">
                        <div className="container">
                            <div className="navigation-main">
                                <ul>
                                    <li> <Link to="/">Home</Link> </li>
                                    <li> <Link to="/about/">About Us</Link> </li>
                                    <li> <Link className={this.props.active == 'practice' ? 'active' : ''} to="/our-practice-areas/mergers-and-acquisitions-private-equity-and-joint-ventures/">Practice Areas</Link> </li>
                                    <li> <Link className={this.props.active == 'team' ? 'active' : ''} to="/team-by-category/Founder & Managing Partner/">Team</Link> </li>
                                    <li> <Link to="/about/#award_intro">Awards</Link> </li>
                                    <li> <Link to="/contact/">Contact</Link> </li>
                                    <li> <Link className={this.props.active == 'blog' ? 'active' : ''} to="/blog/">Blog</Link> </li>
                                </ul>
                            </div>
                        </div>
                        <SiteLogo> </SiteLogo>
                    </div>

                    <div className="site_navigation mobile">
                        <SiteLogo> </SiteLogo>
                        {this.state.isVisible ?
                            <React.Fragment>
                                <button className="close" onClick={() => this.MegaMenu()}> </button>
                                <div className="navigation-main">
                                    <ul>
                                        <li> <Link to="/">Home</Link> </li>
                                        <li> <Link to="/about/">About Us</Link> </li>
                                        <li> <Link className={this.props.active == 'practice' ? 'active' : ''} to="/our-practice-areas/mergers-and-acquisitions-private-equity-and-joint-ventures/">Practice Areas</Link> </li>
                                        <li> <Link className={this.props.active == 'team' ? 'active' : ''} to="/team-by-category/Founder & Managing Partner/">Team</Link> </li>
                                        <li> <Link to="/about/#award_intro">Awards</Link> </li>
                                        <li> <Link to="/contact/">Contact</Link> </li>
                                        <li> <Link className={this.props.active == 'blog' ? 'active' : ''} to="/blog/">Blog</Link> </li>
                                    </ul>
                                </div>
                            </React.Fragment> :
                            <button className="menu_mobile" onClick={() => this.MegaMenu()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        }
                    </div>
                </div>

                {this.state.disclaimer == null ? '' : this.state.disclaimer == false ? ' ' :
                    <React.Fragment>
                        <div className="popup-disclaimer-wrapper" style={{ display: this.state.disclaimer == true ? 'block' : 'none' }}>
                            <div className="popup-disclaimer">
                                <Disclaimer onTitleChange={this.handleTitleChange} ></Disclaimer>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default Nav
