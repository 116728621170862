import React, { Component } from 'react';
import NonLayout from "../components/nonLayout";

class DisclaimerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            pageContent: [],
            ispageLoader: false,
        }
    }

    async componentDidMount() {
        this.getPageDetailsById();
    }

    componentWillReceiveProps(nextProps) {
        this.getPageDetailsById();
    }
    getPageDetailsById() {
        fetch("https://tatva.com/backendapi/api/v1/cms/602ad5e94a831648cdc13822")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ispageLoader: true,
                        pageContent: result.data,
                    });
                },
                (error) => {
                    this.setState({
                        ispageLoader: true,
                        error
                    });
                }
            )
    }

    accept = () => {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('disclaimer', false);
            this.props.onTitleChange(false);
        }
    }

    reject = (props) => {
        window.location.assign("https://www.google.com")
    }

    render() {
        const { pageContent, ispageLoader } = this.state;
        return (

            <NonLayout>
                <div className="main-container">
                    <div className="container">
                        <div className="row justify-content-center cms_pages">
                            <div className="col-md-12 col-sm-12">
                                <h2>Disclaimer</h2>
                                <span dangerouslySetInnerHTML={{ __html: pageContent.description }}></span>
                                {/* <p>By clicking, "I Accept" below, you accept and acknowledge the following:</p>
                                <p>The purpose of this website is to provide general information and insights about Tatva Legal, Hyderabad (and its advocates), and not to advertise or solicit work in any manner whatsoever. </p>
                                <p>Please note that as per the Bar Council of India Rules, advocates in India are prohibited from advertising or soliciting work in any form or manner. You acknowledge that you are visiting this website at your discretion and that there has been no solicitation, invitation, or inducement of any sort whatsoever from Tatva Legal, Hyderabad or any of its advocates in relation to this website.</p>
                                <p>The content available on this website does not constitute legal or other professional advice and should not be substituted for advice relevant to particular circumstances.</p>
                                <p>The access and use of this website does not establish any fiduciary or other relationship between you and Tatva Legal, Hyderabad.</p>
                                <p>Please read the <a href="/terms-of-use" target="_blank">‘Terms of Use’</a> and our <a href="/privacy-policy" target="_blank">‘Privacy Policy’</a> before accessing this website.</p> */}
                                <div className="btn-group1">
                                    <button className="accept" onClick={this.accept}> I Accept </button>
                                    <button className="reject" onClick={this.reject}> I Reject </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NonLayout>
        )
    }
}

    export default DisclaimerPage
